@mixin transform($property) {
    -webkit-transform: $property;
    -ms-transform: $property;
    transform: $property;
  }
  
  @mixin flex-display($align-value,$justify-value,$direction-value) {
    display: flex;
    align-items: $align-value;
    justify-content: $justify-value;
    flex-direction: $direction-value;
  }
  
  @mixin rtl($prop, $inverse-prop , $value) {
  
    html[dir=ltr] & {
      #{$prop}: $value;
    }
  
    html[dir=rtl] & {
      #{$inverse-prop}: $value;
    }
   
  }

  @mixin rtl_prop($prop, $value , $inverse-value) {
  
    html[dir=ltr] & {
      #{$prop}: $value;
    }
  
    html[dir=rtl] & {
      #{$prop}: $inverse-value;
    }
   
  }
  
  @mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1) {
    overflow: hidden;
    position: relative;
    line-height: $lineHeight;
    max-height: $lineHeight * $lineCount;
    min-height: $lineHeight * $lineCount;
    padding-right: 1em;
  }
  