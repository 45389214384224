.NotAvailableWidget {
    border-radius: 8px;
    background-color: #FAF0DD;
    border: 1px solid #EAB14D;
    padding: 2px 5px;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    span {
      flex: 1;
      text-align: center;
    }
  }