.AppLayout{
    margin-top: 114px;
    margin-left: 80px;
    margin-right:80px;
    min-height:calc(100vh - 64px);
}
@media screen and (max-width: 992px) {
    .AppLayout {
        margin-left: 40px;
        margin-right:40px;
    }
  }
  /* On screens that are 600px or less, set the background color to olive */
  @media screen and (max-width: 768px) {
    .AppLayout {
        margin-top: 70px;
        margin-left: 15px;
        margin-right:15px;
        
    }
  }
.AppLayoutForLanding{
    margin-top: 92px;
    margin-left: 0px;
    margin-right: 0px;
    min-height:calc(100vh - 64px);
}
@media screen and (max-width: 992px) {
    .AppLayoutForLanding {
        margin-left: 40px;
        margin-right:40px;
    }
  }
  /* On screens that are 600px or less, set the background color to olive */
  @media screen and (max-width: 768px) {
    .AppLayoutForLanding {
        margin-top: 70px;
        margin-left: 0px;
        margin-right:0px;
        
    }
  }