@import "../../../assets/styles/mixins.scss";

.Footer {
  @include rtl_prop(text-align, left, right);
  background-color: #e7ddeb;

  .Container {
    margin-right: 135px;
    margin-left: 135px;
    .Unused {
      opacity: 0.5;
      cursor: not-allowed;
    }
    .Row {
      margin-left: 40px;
      margin-right: 40px;
    }
  }
  .SocialContainer {
    @include rtl(margin-left, margin-right, 20px);
    margin-top: 73px;
  }
  .fontAwesome {
    color: #b1b1b1;
  }
  .fontAwesomeSocial {
    font-size: 30px;
    color: #730198;
    margin-left: 15px;
    transition-property: all;
    transition-duration: 0.35s;
    transition-timing-function: ease-out;
    transition-delay: 0s;

    &:hover {
      transform: scale(1.6);
    }
  }

  p {
    margin-bottom: 0;
    line-height: 21px;
    font-size: 16px;
  }
  p {
    a {
      color: #2e2e2e;
      &:hover {
        color: black;
        text-decoration: none;
      }
    }
  }

  .googlePlay {
    width: 151px;
    height: 44px;
    transition-property: all;
    transition-duration: 0.35s;
    transition-timing-function: ease-out;
    transition-delay: 0s;

    &:hover {
      transform: scale(1.05);
    }
  }
  .appleLogo {
    margin-bottom: 41px;
    width: 151px;
    height: 44px;
    transition-property: all;
    transition-duration: 0.35s;
    transition-timing-function: ease-out;
    transition-delay: 0s;

    &:hover {
      transform: scale(1.05);
    }
  }
  .ParaSubHeader {
    color: #68046b;
  }
  .ParaHeader {
    color: #68046b;
    font-weight: bold;
    margin-top: 73px;
    margin-bottom: 13px;
  }
  .FooterLogo {
    margin-top: 60px;
    margin-bottom: 36px;
    height: 58px;
    width: 200px;
  }
  .ContactUs {
    font-size: 14px;
    line-height: 20px;
  }
  .Form {
    margin-bottom: 95px;
  }

  hr {
    border: none;
    height: 4px;
    background-color: #cfc7d2;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .CopyRights {
    margin-top: 28px;
    padding-bottom: 43px;
    p {
      font-size: 14px;
      line-height: 19px;
    }
  }
  .FooterEnglish {
    font-family: Euclid;
  }
}
@media screen and (max-width: 1024px) {
  .Footer {
    .Container {
      margin-right: 80px;
      margin-left: 80px;
    }
    .ParaHeader {
      margin-top: 40px;
    }
    .FooterLogo {
      margin-top: 32px;
    }
    .SocialContainer {
      margin-top: 30px;
    }
  }
}
@media screen and (max-width: 778px) {
  .Footer {
    .Container {
      margin-right: 15px;
      margin-left: 15px;
      .Row {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
    .SocialContainer {
      @include rtl(margin-left, margin-right, 0);
    }
    .ParaHeader {
      margin-top: 37px;
    }
    .FooterLogo {
      margin-top: 30px;
      margin-bottom: 28px;
    }
    .appleLogo {
      margin-bottom: 0px;
    }
    .Form {
      margin-bottom: 30px;
    }
    .CopyRights {
      margin-top: 20px;
      padding-bottom: 18px;
    }
  }
}
