.BookingServiceButton {
    button{
  width: 100%;
  height: 70px;
  background-color: #8802b4;
  font-weight: bold;
  font-size: 16px;
  border-radius: 8px;
  border: none;
  color: white;
  padding: auto;
  line-height: 21px;
  &:hover,
  &:active,
  &:visited {
    background-color: #76049c;
      color: white;
  }
}
}
