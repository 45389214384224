@import '../../../../assets/styles/mixins.scss';
.HealthyDateSelect {
  position: relative;
  select {
    width: 120px;
    height: 45px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e7ddeb;
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    
  }
  .addonCustom  {
    position: absolute;
    @include rtl(left , right ,75%);
    top: 2px;
    padding-left: 7px;
    width: 10%;
    margin-top: 8px;
    height: 25px;
    cursor: pointer;
    color: #B1B1B1;
  }
}
@media (max-width: 577px) {
  .HealthyDateSelect {
    select {
      width: 85px;
      height: 45px;
    }
  }
}

    @media (max-width: 320px) {
      .HealthyDateSelect {
        select {
          width: 68px;
          height: 45px;
        }
      }
    }
