@import '../../../../assets/styles/mixins.scss';
.AuthLayout {
  @include rtl(padding-right,padding-left,30px);
  .formLayout {
    background: #FCFCFC 0% 0% no-repeat padding-box;
    margin-bottom: 41px;
    margin-top: 28px;
    box-shadow: 0px 2px 4px #00000029;
    border-radius: 3px;
  }
  .imgLogin {
    max-height: 347px;
  }
  .imgRegister {
    max-height: 318px;
  }
  .img{
    width: 263px;
    height: 75px;
  }
}
@media screen and (max-width: 768px) {
  .AuthLayout {
    @include rtl(padding-right,padding-left,0);
    img {
      margin-bottom: 36px;
    }
    .formLayout {
      margin-bottom: 36px;
      margin-top: 33px;
    }
  }
}
