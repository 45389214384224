@import "../../../../assets/styles/mixins.scss";
.TimeCard {
  background-color: #fff;
  border: 1px solid #e7ddeb;
  border-radius: 8px;
  width: 166px;
  height: 116px;
  input {
    -webkit-appearance: none;
    background-color: #fff;
    border: 1px solid #68046b;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    width: 19px;
    height: 19px;
    border-radius: 2px;
    display: inline-block;
    position: relative;
  }
  // input:checked {
  //   background-color: #fc0095;
  //   border: 1px solid #68046b;
  //   box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
  //     inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
  //     inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  //   color: #99a1a7;
  // }
  // input:checked:after {
  //   content: "\2713";
  //   font-size: 19px;
  //   position: absolute;
  //   top: -7px;
  //   font-weight: bold;
  //   color: #fff;
  //   @include rtl(left, right, 1px);
  // }
}
@media screen and (max-width: 788px) {
  .TimeCard {
    width: 150px;
    height: 116px;
  }
}
@media screen and (max-width: 577px) {
  .TimeCard {
    width: 165px;
    height: 116px;
  }
}
@media screen and (max-width: 375px) {
  .TimeCard {
    width: 146px;
    height: 116px;
  }
}
@media screen and (max-width: 360px) {
  .TimeCard {
    width: 137px;
    height: 104px;
  }
}




@media screen and (max-width: 320px) {
  .TimeCard {
    width: 120px;
    height: 89px;
  }
}
