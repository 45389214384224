@import "../../../../../assets/styles/mixins.scss";
.HealthyInfo {
  @include rtl_prop(text-align, left, right);
  background-color: #fcfcfc;
  margin-bottom: 16px;
  h3 {
    font-size: 16px;
    line-height: 37px;
    color: #4b0064;
    margin-bottom: 0px;
  }
  .Heads {
    @include rtl(padding-left, padding-right, 58px);
    padding-top: 23.5px;
    padding-bottom: 41.5px;
  }
  .Info {
    padding-top: 23.5px;
    padding-bottom: 41.5px;
    p {
      font-size: 16px;
      line-height: 37px;
      margin-bottom: 0;
    }
  }
  .NumericInfo{
    @include rtl(padding-left, padding-right, 40px);
    .bloodImg{
      margin-top: 22px;
      position: relative;
      width: 60px;
      height: 60px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 60px;
      background-image: url('../../../../../assets/img/account/Group\ 62405.png');
    }
    
    .NoData {
        font-size: 16px;
        line-height: 21px;
        font-weight: bold;
        color: #b1b1b1;
        margin-top: 12px;
      }
      .Data {
        font-size: 20px;
        line-height: 27px;
        color: #4b0064;
        font-weight: bold;
        margin-top: 8px;
      }
    .Images{
        width: 90%;
        .img{
            margin-top: 22px;
            height: 60px;
        }
    }
  }
}
@media (max-width: 577px) {
  .HealthyInfo {
    margin-bottom: 10px;
    h3 {
      line-height: 20px;
    }
    .Heads {
      @include rtl(padding-left, padding-right, 22px);
      padding-top: 53px;
      padding-bottom: 39px;
    }
    .Info {
      p {
        line-height: 20px;
      }
      padding-top: 53px;
      padding-bottom: 39px;
    }
    .NumericInfo{
        .Images{
        img{
            margin-top: 0px
        }}
      }
  }
}
