.OrderCallButton {
  button {
    width: 161px;
    height: 45px;
    background-color: #8802b4;
    font-weight: bold;
    font-size: 20px;
    border: none;
    color: white;
    padding: auto;
    line-height: 27px;
  }
  button:hover,
  button:active,
  button:visited {
    background-color: #8802b4;
    color: white;
  }
}
