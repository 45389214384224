@import "../../../../assets/styles/mixins.scss";
.BookingService {
  @include rtl_prop(text-align, left, right);
  .Button {
    margin-bottom: 35px;
  }
  margin-top: 22px;
  p {
    margin-bottom: 0px;
  }
  span {
    margin-bottom: 0px;
  }
  h3 {
    margin-bottom: 0px;
  }
  hr {
    border: none;
    height: 2px;
    background-color: #e7ddeb;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .GridPadding {
    padding-left: 8px;
    padding-right: 8px;

    @media screen and (min-width:1400px){
      flex: 0 0 33.33% !important;
      max-width: 33.33% !important;
    }
  }

  .BreadCrumbCustom {
    background-color: transparent;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 26px;
    li {
      font-size: 14px;
      line-height: 21px;
      font-weight: bold;
      a {
        color: #4b0064;
      }
      span {
        color: #a57fb1;
      }
      li + li:before {
        color: #ccc;
        content: "/ ";
        padding: 0 5px;
      }
    }
  }
  .Specializations {
    background-color: #fcfcfc;
    border-radius: 8px;
    @include rtl(padding-left, padding-right, 69px);
    @include rtl(padding-right, padding-left, 84px);
    padding-bottom: 57px;
    margin-bottom: 44px;
    .GrayCircle {
      width: 123px;
      height: 125px;
      border-radius: 50%;
      margin-top: 24px;
      margin-bottom: 17px;
    }
    h3 {
      font-size: 24px;
      line-height: 37px;
      color: #4b0064;
    }
    p {
      font-size: 14px;
      line-height: 37px;
      color: #4b0064;
      margin-bottom: 28px;
    }
    .FirstSection {
      h3 {
        font-size: 28px;
        line-height: 21px;
        color: #252525;
        margin-bottom: 4px;
      }
      p {
        font-size: 24px;
        line-height: 37px;
        color: #252525;
      }
      .FirstSectionChild {
        margin-top: 38px;
      }
    }
  }
  .CallRequest {
    padding-right: 16px;
    padding-left: 16px;
    background-color: #fcfcfc;
    border-radius: 8px;

    p {
      font-size: 15px;
      line-height: 20px;
    }
    img {
      margin-top: 38px;
      margin-bottom: 33px;
    }
    a {
      margin-top: 30px;
      margin-bottom: 23px;
      width: 75%;
      background-color: #fff;
      color: #68046b;
      height: 45px;
      border: 2px solid #68046b;
      font-weight: bold;
      padding: 10px 15px;
      &:hover {
        background-color: #68046b;
        color: #fff;
      }
    }
  }
  .DoctorsRegistered {
    .DoctorsRegisteredAbs {
      position: absolute;
      top: -24px;
      right: 11px;
    }
    position: relative;
    background-color: #fcfcfc;
    box-shadow: 0px 2px 4px #00000029;
    border-radius: 8px;
    margin-top: 18px;
    padding-bottom: 29px;
    margin-bottom: 20px;
    img {
      margin-top: 34px;
      margin-bottom: 21px;
    }
    p {
      font-size: 16px;
      line-height: 21px;
      font-weight: bold;
      color: #4b0064;
    }
  }
}
@media screen and (max-width: 1024px) {
  .BookingService {
    .Specializations {
      @include rtl(padding-left, padding-right, 40px);
      @include rtl(padding-right, padding-left, 40px);
    }
  }
}
@media screen and (max-width: 778px) {
  .BookingService {
    margin-top: 8px;
    .Button {
      margin-bottom: 35px;
    }
    .GridPadding {
      padding-left: 4px;
      padding-right: 4px;
    }

    .Specializations {
      @include rtl(padding-left, padding-right, 25px);
      @include rtl(padding-right, padding-left, 25px);
      padding-bottom: 29px;
      margin-bottom: 14px;
      .GrayCircle {
        margin-right: auto;
        margin-left: auto;
      }
      .FirstSection {
        .FirstSectionChild {
          margin-top: 4px;
        }
      }
      h3 {
        font-size: 24px;
        line-height: 32px;
        margin-top: 30px;
      }
      p {
        font-size: 14px;
        line-height: 22px;
      }
    }
    .CallRequest {
      a {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .BookingService {
    .Specializations {
      @include rtl(padding-left, padding-right, 10px);
      @include rtl(padding-right, padding-left, 10px);
      .FirstSection {
        p {
          font-size: 21px;
        }
        h3 {
          font-size: 24px;
        }
      }
    }
  }
}
