.HealthyHome{
    .SubSection{
        margin-bottom: 78px;
    }
}
@media (max-width: 577px) {
    .HealthyHome{
    .SubSection{
        margin-bottom: 36px;
    }
}
}