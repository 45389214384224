@import '../../../../../../assets/styles/mixins.scss';
.AccountAddressForm {
    @include rtl_prop(text-align, left, right);
  .Button {
      margin-top: 40px;
      margin-bottom: 44px;
    .AuthButton {
      width: auto;
      font-family: din;
      line-height: 26px;
      padding: 11px 91px;
      &:first-child {
        @include rtl(margin-left,margin-right,10px);
      }
    }
  }
  .Map{
    width: 60%;
  }
  p {
    font-size: 16px;
    line-height: 37px;
    font-weight: bold;
    margin-bottom: 12px;
    color: #4b0064; 
  }
}
@media (max-width: 577px) {
  .AccountAddressForm {
    .Map{
      width: 100%;
    }
    .Button {
    margin-top: 34px;
      margin-bottom: 31px;
      .AuthButton {
        width: 116px;
      }
    }
  }
}
