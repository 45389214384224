@import "../../../assets/styles/mixins.scss";

.Header {
  z-index: 9999;
  @include rtl_prop(text-align, left, right);
  @include rtl(padding-left, padding-right, 71px);
  @include rtl(padding-right, padding-left, 90px);
  background: linear-gradient(180deg, #ffffff 41.67%, #fafafa 100%);
  box-shadow: 0px 13.8485px 17.869px rgba(110, 0, 48, 0.0298054),
    0px 7.76336px 10.0172px rgba(110, 0, 48, 0.025),
    0px 4.12306px 5.32008px rgba(110, 0, 48, 0.0201946),
    0px 1.7157px 2.21381px rgba(110, 0, 48, 0.0140573);

  .HeaderLogoBrand {
    width: 147px;
    height: 41px;
  }
  .PrimeButton {
    background: linear-gradient(90deg, #ae2f8d 0%, #76028a 100%);
    border-radius: 13px;
    padding: 10px 20px;
    height: 47px;
    border: none;
    span {
      font-size: 16px;
      line-height: 27px;
      color: #fff;
      margin-bottom: 0;
      font-weight: bold;
    }

    &:hover {
      background: linear-gradient(90deg, #8b2670 0%, #4a0257 100%);
      box-shadow: none;
    }
    &:focus {
      box-shadow: none;
    }
  }
  .LangButton {
    background: #fff;
    border-radius: 13px;
    height: 47px;
    border: 2px solid #7c126b;
    padding: 10px 20px;
    span {
      font-size: 16px;
      line-height: 27px;
      font-weight: 700;
      color: #7c126b;
      text-transform: capitalize;
      margin-bottom: 0;
      font-family: Euclid;
    }
    &:hover {
      background-color: #dee2e6;
      border: 2px solid #7c126b;
    }
    &:focus {
      border: 2px solid #7c126b;
      background-color: #fff;
      box-shadow: none;
    }
    .LangFont {
      width: 19px;
      height: 19px;
    }
  }

  height: 90px;
  .NavLinkCustom {
    margin-bottom: 0;
    color: #4b0064;
    line-height: 27px;
    font-size: 18px;
    font-weight: 700;
    &:hover {
      opacity: 0.5;
    }
  }
  .Unused {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .dropDownP {
    line-height: 21px;
    font-size: 16px;
  }
  .dropDownNav {
    line-height: 21px;
    font-size: 16px;
    font-weight: bold;
  }
  a {
    margin-bottom: 0;
    font-size: 13px;
    color: #4b0064;
    line-height: 17px;
    padding-bottom: 5px;
    &:hover {
      color: #730198;
      text-decoration: none;
    }
  }

  .navbarTogglerIcon {
    background-image: url("../../../assets/img/Header/menu-button-of-three-horizontal-lines.png");
    background-size: 24px 13px;
  }
  .navbarTogglerIconClose {
    background-image: url("../../../assets/img/Header/Group\ 62197.png");
    background-size: 13px 13px;
  }

  .DropDownMd {
    position: absolute;
    padding: 17px 0px;
    width: 208px;
    border-radius: 1px 1px 8px 8px;
    @include rtl(margin-left, margin-right, auto);
    &:after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      bottom: 100%;
      @include rtl(right, left, 8px);
      border-width: 0 12px 12px 12px;
      border-style: solid;
      border-color: #fff transparent;
      overflow: hidden;
      -webkit-filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.5));
      filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.5));
    }
  }
  .dropdownMenu {
    @include rtl(right, left, 7px);
    background-color: #fff;
    border-top: 1px solid rgb(212, 210, 210);
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #ebebeb;
    a {
      height: 48px;
      display: flex !important;
      align-items: center;
      font-size: 16px;
      line-height: 21px;
      &:focus {
        background-color: #eedcff;
      }
      &:hover {
        background-color: #e9ecef;
      }
    }
  }
  .mainNav {
    li {
      background-color: transparent;
    }
  }
  .dropdownItem {
    padding-right: 0rem;
    padding-left: 0rem;
  }
  .en {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-top: 8px;
    background-color: #e7ddeb;
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: bold;
      line-height: 19px;
      text-transform: capitalize;
    }
  }
  .userNullImg {
    width: 40px;
    height: 40px;
    padding: 0px;
    border-radius: 50%;
    border: 3px solid #730198;
  }
  .collapseNav {
    box-shadow: 0px 3px 6px #00000029;
  }
  .dropdownToggle::after {
    content: none;
  }
  .DividerSm {
    margin: 0px;
    border: 1px solid #e5e5e5;
  }
}
@media screen and (max-width: 1024px) {
  .Header {
    .NavLinkCustom {
      font-size: 17px;
    }
  }
}
@media screen and (max-width: 778px) {
  .Header {
    height: 70px;
    @include rtl(padding-left, padding-right, 15px);
    @include rtl(padding-right, padding-left, 15px);
    padding: 0px;

    .LangButton {
      margin-top: 30px;
    }
    .NavLinkCustom {
      font-size: 16px;
      font-weight: normal;
    }
    .activeLink {
      font-weight: bold;
      border-bottom: none;
    }
    .DividerSm {
      margin: -1px;
      border: 1px solid #e5e5e5;
    }
    li {
      height: 48px;
      .navItem {
        margin: 0;
      }
    }
    ul {
      padding-inline-start: 0px;
    }

    .dropdownMenu {
      box-shadow: none;
      width: 106%;
      margin-top: -8px;
      border-left: none;
      border-right: none;
      border-bottom: none;
      background-color: #fff;
      @include rtl(margin-left, margin-right, -18px);
    }
    .dropdownItem {
      padding: 0;
    }
    .navSmHeader {
      margin-top: 10px; // modified was 33px
      background-color: #fff;
      padding-bottom: 15px;
      padding-left: 20px;
      padding-right: 20px;
    }
    .mainNav {
      margin-top: -2px;
      padding-left: 20px !important;
      padding-right: 20px !important;

      li {
        padding-right: 13px;
        padding-left: 13px;
        padding-top: 5px;
        background-color: #fcfcfc;
      }
    }
    a {
      font-size: 16px;
      line-height: 21px;
    }
    p {
      font-size: 16px;
      line-height: 21px;
    }
  }
}
@media screen and (max-width: 577px) {
  .Header {
    .dropdownMenu {
      width: 108%;
    }
  }
}
@media screen and (max-width: 414px) {
  .Header {
    .dropdownMenu {
      width: 111%;
    }
  }
}
@media screen and (max-width: 375px) {
  .Header {
    .dropdownMenu {
      width: 114%;
    }
  }
}
@media screen and (max-width: 320px) {
  .Header {
    .PrimeButton {
      p {
        font-size: 13px;
      }
      span {
        font-size: 13px;
      }
    }
    .LangButton {
      padding: 5px 10px;
      p {
        font-size: 13px;
      }
      span {
        font-size: 13px;
      }
    }
    .NavLinkCustom {
      font-size: 13px;
    }
    .dropdownMenu {
      width: 118%;
    }
  }
}
// dropdown menu animations
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slide {
  0% {
    margin-top: -10px;
  }
  100% {
    margin-top: 0px;
  }
}
@keyframes fade_reverse {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes slide_reverse {
  0% {
    margin-top: 0px;
  }
  100% {
    margin-top: -10px;
  }
}
.fade {
  animation-name: fade, slide;
  animation-duration: 200ms, 300ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear, cubic-bezier(0.23, 1, 0.32, 1);
  animation-delay: 100ms, 0;
}

.fadeReverse {
  animation-name: fade_reverse, slide_reverse;
  animation-duration: 100ms, 200ms;
  animation-timing-function: linear, cubic-bezier(0.23, 1, 0.32, 1);
  animation-delay: 100ms, 0;
}
