@import "../../../../assets/styles/mixins.scss";

.Modal {
  @include rtl_prop(font-family,Euclid,Questv1);
  @include rtl_prop(text-align, left, right);
  padding-top: 42px;
  padding-left: 123px;
  padding-right: 123px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  background-color: white;
  width: 512px;
  height: 427px;
  border-radius: 3%;
  .FaCustom{
    position: absolute;
    top: 16px;
    @include rtl( right,left ,18px);
    font-size: 20px;
    height: 32px;
    width: 32px;
    color : black;
    background-color: #E7DDEB;
    border-radius: 50%;
    cursor: pointer;
    &:hover{
      background-color: #dab6e9;
    }
    
  }
  h3{
    font-size: 24px;
    line-height: 37px;
    margin-top: 21px;
    margin-bottom: 11px;
    font-weight: bold;
  }
  p{
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 44px;
  }
  .register {
    width: 100%;
    background: #EEDCFF 0% 0% no-repeat padding-box;
    border-radius: 8px;
    color: #68046b;
    height: 58px;
    border: none;
    margin-top: 12px;
    padding-top: 14px;
    &:hover{
      background-color: #d6cdda;
      color: #68046b;
    }
    
    p{
      font-size: 20px;
      font-weight: bold;
      line-height: 27px;
    }
  }
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
}
@media screen and (max-width: 778px) {
  .Modal {
    width: 90%;
    padding-top: 42px;
    padding-left: 73px;
    padding-right: 73px;
  }
}
@media screen and (max-width: 577px) {
  .Modal {
    padding-left: 59px;
    padding-right: 59px;
  }
}
@media screen and (max-width: 320px) {
  .Modal {
    padding-left: 30px;
    padding-right: 30px;
  }
}