@import "../../../../../assets/styles/mixins.scss";
.HealthyForm {
  padding-top: 50px;
  padding-bottom: 30px;
  @include rtl_prop(text-align, left, right);
  @include rtl(padding-left, padding-right, 58px);
  background-color: #fcfcfc;
  margin-bottom: 16px;
  p {
    margin-bottom: 0;
  }
  .Label {
    font-size: 16px;
    line-height: 37px;
    color: #4b0064;
    font-weight: bold;
  }
  .WeightSection {
    margin-top: 35px;
    margin-bottom: 16px;
    .WeightImg {
      width: 30px;
      height: 30px;
      @include rtl(margin-right, margin-left, 20px);
    }
  }
  .HeightSection {
    margin-bottom: 16px;
    .HeightImg {
      width: 20px;
      height: 30px;
      @include rtl(margin-right, margin-left, 30px);
    }
  }
  .GenderSection{
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .BloodCard {
    width: 90px;
    height: 90px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #e7ddeb;
    border-radius: 8px;
    @include rtl(margin-right, margin-left, 16px);
    position: relative;
    display: inline-block;
    cursor: pointer;
    transition-property: all;
    transition-duration: 0.35s;
    transition-timing-function: ease-out;
    transition-delay: 0s;

  &:hover {
    transform: scale(1.2);
  }
    .bloodImg {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: auto;
      margin-right: auto;
      position: absolute;
      width: 60px;
      height: 60px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 60px;
      background-image: url("../../../../../assets/img/account/Group\ 62405.png");
    }
  }
  .SmokerSection {
    margin-bottom: 13px;
  }
  .SelectBox {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e7ddeb;
    width: 100px;
    height: 45px;
    color: #b1b1b1;
    cursor: pointer;
    .FontAwesomeIcon {
      font-size: 24px;
    }
  }
  .SaveButton {
    width: 116px;
    margin-top: 0px;
    @include rtl(margin-right, margin-left, 33px);
  }
}
@media (max-width: 1024px) {
  .HealthyForm {
    @include rtl(padding-left, padding-right, 40px);
  }
}
@media (max-width: 778px) {
  .HealthyForm {
    @include rtl(padding-left, padding-right, 25px);
  }
}
@media (max-width: 577px) {
  
  .HealthyForm {
    .Label {
      margin-bottom: 7px;
    }
    padding-top: 33px;
    @include rtl(padding-left, padding-right, 0px);
    .WeightSection {
      margin-top: 26px;
      margin-bottom: 12px;
    }
    .HeightSection {
      margin-bottom: 12px;
    }
    .GenderSection{
      margin-top: 12px;
      margin-bottom: 12px;
    }
    .SaveButton {
      width: 116px;
      margin-top: 41px;
      @include rtl(margin-right, margin-left, 20px);
    }
    
  }
}
@media (max-width: 375px) {
  .HealthyForm {
  .BloodCard {
    @include rtl(margin-right, margin-left, 5px);
  }}
}
