.AccountNoAddress {
  .MarkerImg {
    margin-top: 85px;
    margin-bottom: 20px;
  }
  p {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 0px;
  }
  .PlusImg {
    margin-top: 8px;
    margin-bottom: 85px;
    cursor: pointer;
    transition-property: all;
    transition-duration: 0.35s;
    transition-timing-function: ease-out;
    transition-delay: 0s;

  &:hover {
    transform: scale(1.2);
  }
  }
}
@media screen and (max-width: 778px) {
  .AccountNoAddress {
    .MarkerImg {
      margin-top: 24px;
    }
    .PlusImg {
        margin-top: 8px;
        margin-bottom: 43px;
      }
  }
}
