.Account{
    
    .SecondSection{
        margin-bottom: 41px;
    }
}
@media (max-width: 577px) {
    .Account{
    .SecondSection{
        margin-bottom: 27px;
    }
}
}