@import "../../../../../assets/styles/mixins.scss";
.CallRequestPhoneForm {
  padding-right: 40px;
  padding-left: 40px;
  h3 {
    font-size: 35px;
    line-height: 37px;
    font-weight: bold;
    margin-top: 13px;
    margin-bottom: 19px;
  }
  p {
    line-height: 21px;
    font-size: 16px;
    margin-bottom: 0;
  }
  .Button{
    margin-top:100px;
    margin-bottom: 75px
  }
  .Checkbox {
    @include rtl(padding-left, padding-right, 3px);
    -webkit-appearance: none;
    background-color: #fff;
    border: 1px solid #68046b;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    width: 19px;
    height: 19px;
    border-radius: 2px;
    display: inline-block;
    position: relative;
  }
  .Checkbox:checked {
    background-color: #fc0095;
    border: 1px solid #68046b;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
      inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: #99a1a7;
  }
  .Checkbox:checked:after {
    content: "\2713";
    font-size: 19px;
    position: absolute;
    top: -7px;
    font-weight: bold;
    color: #fff;
  }
}
@media screen and (max-width: 778px) {
  .CallRequestPhoneForm {
      padding-right: 16px;
      padding-left: 16px;
    h3 {
      margin-top: 12px;
      margin-bottom: 26px;
    }
    .Button{
        margin-top:30px;
        margin-bottom: 20px
      }
  }
}
