.VerficationInput {
  input {
    text-align: center;
    background: #EEEEEE 0% 0% no-repeat padding-box;
    border-radius: 12px;
    font-size: 35px;
    font-weight: bold;
    color: #000000;
    height: 58px;
    width: 58px;
    &:focus {
      text-align: center;
      &:focus{
        box-shadow: none;
        border: 2px solid #8802B4;
        background-color: #F4F4FC;
      }
    }
  }
}

@media screen and (max-width: 778px) {
  .VerficationInput {
    input {
      width: 45px;
      height: 45px;
    }
  }
}
@media screen and (max-width: 577px) {
  .VerficationInput {
    input {
      width: 56px;
      height: 58px;
    }
  }
}
@media screen and (max-width: 320px){
  .VerficationInput {
    input {
      width: 50px;
      height: 53px;
    }
  }
}
