.HealthyRegularInput {
  input {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e7ddeb;
    height: 45px;
    &:focus{
      box-shadow: none;
      border: 2px solid #8802B4;
      
    }
  }
  .Appended {
    background-color: #fff;
    width: 55px;
    display: flex;
    justify-content: center;
    color: #b1b1b1;
  }
}
