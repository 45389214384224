@import "../../../../../assets/styles/mixins.scss";
.AccountHeader {
  @include rtl_prop(text-align, left, right);
  background-color: #fcfcfc;
  margin-top: 22px;
  margin-bottom: 23px;
  border-radius: 8px;
  .bloodImg{
    position: relative;
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 60px;
    background-image: url('../../../../../assets/img/account/Group\ 62405.png');
  }
  h3 {
    font-size: 28px;
    line-height: 21px;
    margin-bottom: 9px;
    margin-top: 37px;
  }
  p {
    font-size: 20px;
    font-weight: bold;
    line-height: 27px;
    color: #4b0064;
    margin-bottom: 0px;
  }

  .HeaderImgContainer {
    margin-top: 24px;
    margin-bottom: 24px;
    width: 119px;
    height: 119px;
    border-radius: 50%;
    border: 5px solid #730198;
    position: relative;
    .ImgResize{
      width: 119px;
    height: 119px;
    border-radius: 50%;
    }
    
    .CameraImg {
      position: absolute;
      top: 0;
      cursor: pointer;
      @include rtl(left, right, 0);
    }
    input {
      display: none;
    }
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .MyHealthy {
    .HealthyLink{
      color: #4b0064;
      &:hover{
        text-decoration: none;
        color: #2d013b;
      }
    }
    p {
      font-size: 16px;
      color: #4b0064;
      line-height: 37px;
      font-weight: normal;
      margin-bottom: 0;
    }
    .NoData {
      font-size: 16px;
      line-height: 21px;
      font-weight: bold;
      color: #b1b1b1;
      margin-top: 12px;
    }
    .Data {
      font-size: 20px;
      line-height: 27px;
      color: #4b0064;
      font-weight: bold;
      margin-top: 8px;
    }
    img {
      max-height: 60px;
    }
  }
}
@media screen and (max-width: 577px) {
  .AccountHeader {
    h3 {
      margin-top: 0px;
    }
    margin-top: 17px;
    margin-bottom: 13px;
    .HeaderImgContainer {
      margin-bottom: 0;
      margin-top: 18px;
    }
    .MyHealthy {
        p{
            margin-bottom: 13px;
        }
    }
  }
}
