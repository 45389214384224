@import "../../../../../assets/styles/mixins.scss";
.HealthyAttachmentForm {
  @include rtl_prop(text-align, left, right);
  background: #fcfcfc 0% 0% no-repeat padding-box;
  border-radius: 8px;
  margin-bottom: 30px;
  .TableContainer {
    @include rtl(padding-left, padding-right, 46px);
    table {
      margin-bottom: 30px;
      box-shadow: 0px 3px 6px #00000029;
      border-collapse: collapse;
      border-radius: 8px;
      overflow: hidden;
      thead {
        text-align: center;
        tr {
          th {
            text-align: center;
            background-color: #733097;
            color: #ffffff;
          }
        }
      }
      tbody {
        background-color: #ffffff;
        tr{
          td{
            text-align: center;
          }
        }
      }
    }
  }
  p {
    font-size: 16px;
    line-height: 37px;
    font-weight: bold;
    color: #4b0064;
    padding: 0;
  }
  .HeadSection {
    @include rtl(padding-left, padding-right, 46px);
    .SectionImg {
      width: 58px;
      height: 58px;
      margin-top: 19px;
      margin-bottom: 10px;
    }
    .Head {
      margin-top: 36px;
      margin-bottom: 29px;
      @include rtl(margin-left, margin-right, 14px);
    }
  }
  .FormSection {
    @include rtl(padding-left, padding-right, 66px);
  }
  hr {
    margin: 0;
    border: 2px solid #707070;
    opacity: 0.2;
    margin-bottom: 27px;
  }

  .Textarea {
    height: 115px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e7ddeb;
    border-radius: 8px;
    &:focus {
      box-shadow: none;
      border: 2px solid #8802b4;
    }
  }
  input[type="file"] {
    display: none;
  }
  label {
    width: 116px;
    height: 116px;
    background-color: #fff;
    border: 1px solid #e7ddeb;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    &:hover {
      .FaCustom {
        visibility: visible;
      }
    }
    .Img {
      width: 100%;
      height: 100%;
    }
    .FaCustom {
      position: absolute;
      @include rtl(right, left, 4px);
      top: 4px;

      visibility: hidden;
    }
  }
  .ButtonContainer {
    margin-top: 12px;
    @include rtl(padding-left, padding-right, 46px);
    .SubmitButton {
      width: 116px;
      margin-bottom: 49px;
    }
    .BackButton {
      width: 116px;
      height: 58px;
      font-weight: bold;
      font-size: 20px;
      border: none;
      color: #8802b4;
      background-color: #e7ddeb;
      padding: 15px 20px;
      line-height: 27px;
      border-radius: 8px;
      &:hover {
        background-color: #d6cdda;
        box-shadow: none;
      }
      &:focus {
        box-shadow: none;
      }
    }
  }
}
@media (max-width: 577px) {
  .HealthyAttachmentForm {
    .TableContainer {
      @include rtl(padding-left, padding-right, 15px);
    }
    .HeadSection {
      @include rtl(padding-left, padding-right, 22px);
    }
    .FormSection {
      @include rtl(padding-left, padding-right, 15px);
      @include rtl(padding-right, padding-left, 15px);
    }
    .ButtonContainer {
      @include rtl(padding-left, padding-right, 15px);
      .SubmitButton {
        margin-bottom: 27px;
      }
    }
  }
}
