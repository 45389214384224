@import "../../../../../../assets/styles/mixins.scss";
.CheckBox {
  @include rtl_prop(text-align, left, right);
  @include rtl(padding-left, padding-right, 17px);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #e7ddeb;
  border-radius: 8px;
  padding-top: 13px;
  height: 95px;
  width: 136px p {
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 21px;
  }
  label > span > div {
    border-radius: 2px !important;
  }
  // input {
  //   @include rtl(padding-left, padding-right, 2px);
  // }
  // input:checked {
  //   background-color: #fc0095;
  //   border: 1px solid #68046b;
  //   box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
  //     inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
  //     inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  //   color: #99a1a7;
  // }
  // input:checked:after {
  //   content: "\2713";
  //   font-size: 19px;
  //   position: absolute;
  //   top: -7px;
  //   font-weight: bold;
  //   color: #fff;
  // }
}
