@import '../../../../../assets/styles/mixins.scss';
.CallRequestForm{
    @include rtl_prop(text-align, left, right);
    h3{
        font-size: 24px;
        font-weight: bold;
        line-height: 37px;
        color: #4B0064;
        margin-bottom: 0px;
        margin-top: 35px;
    }
    .secondHeader{
        font-size: 20px;
        margin-top: 30px;
    }
    .Textarea{
        margin-top: 9px;
        margin-bottom: 38px;
        height: 115px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #E7DDEB;
        border-radius: 8px;
        &:focus{
            box-shadow: none;
            border: 2px solid #8802B4;
            
          }
    }
    p{
        font-size: 14px;
        line-height: 22px;
        color: #4B0064;
        margin-bottom: 0px;
    }
    .restBoxPadding{
        padding-left: 5px;
        padding-right: 5px;
    }
}
@media screen and (max-width: 778px) {
    .CallRequestForm{
        h3{
            margin-top: 18px;
        }
        .secondHeader{
            margin-top: 20px;
        }
        .Textarea{
            margin-top: 9px;
            margin-bottom: 35px;
            height: 149px;
        }
    }
}