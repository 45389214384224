@import "../../../../assets/styles/mixins.scss";
.BookingServiceForm {
  @include rtl_prop(text-align, left, right);
  .BreadCrumbCustom {
    background-color: transparent;
    padding: 0px;
    li {
      font-size: 14px;
      line-height: 37px;
      font-weight: bold;
      a {
        color: #4b0064;
      }
      span {
        color: #a57fb1;
      }
    }
  }
  .FirstSection {
    border-radius: 8px;
    padding-top: 34px;
    padding-bottom: 66px;
    padding-left: 64px;
    padding-right: 64px;
    background-color: #fcfcfc;
    margin-bottom: 47px;
    h3 {
      font-size: 20px;
      line-height: 37px;
      color: #4b0064;
      margin-bottom: 0px;
    }
    img {
      max-width: 100%;
      max-height: 100%;
    }
    .TextArea {
      height: 115px;
      margin-bottom: 13px;
      border: 1px solid #e7ddeb;
      border-radius: 8px;
      &:focus {
        box-shadow: none;
        border: 2px solid #8802b4;
      }
    }
    input[type="text"] {
      &:focus {
        box-shadow: none;
        border: 2px solid #8802b4;
      }
    }
    input[type="file"] {
      display: none;
    }
    label {
      width: 116px;
      height: 116px;
      background-color: #fff;
      border: 1px solid #e7ddeb;
      border-radius: 8px;
      cursor: pointer;
      position: relative;
      &:hover {
        .FaCustom {
          visibility: visible;
        }
      }
      .FaCustom {
        position: absolute;
        color: rgb(247, 0, 255);
        visibility: hidden;
      }
    }
    input[type="text"] {
      height: 45px;
      margin-bottom: 30px;
    }
  }
  .SecondSection {
    @include rtl(padding-right, padding-left, 0);
    @include rtl(padding-left, padding-right, 15px);
    .TimeSlotWrapper {
      padding: 0 80px;
    }
    .CalenderSection {
      box-shadow: 0px 2px 4px #00000029;
      border-radius: 8px;
      background-color: #fcfcfc;
      padding-top: 19px;
      padding-bottom: 30px;
      .Calender {
        padding-left: 80px;
        padding-right: 80px;
        @include rtl_prop(font-family,Euclid,Noto);
      }
      .CalenderTime {
        padding-left: 35px;
        padding-right: 35px;
        margin-top: 36px;
      }
      h3 {
        font-size: 20px;
        line-height: 37px;
        color: #4b0064;
        margin-bottom: 37px;
      }
    }
    .Button {
      margin-top: 26px;
    }
  }
  .PaymentSection {
    @include rtl(padding-right, padding-left, 0);
    @include rtl(padding-left, padding-right, 15px);
    margin-bottom: 20px;
    .Label {
        font-size: 20px;
        line-height: 37px;
        color: #4b0064;
        margin-bottom: 25px;
    }
    .PaymentMethodSection {
      box-shadow: 0px 2px 4px #00000029;
      border-radius: 8px;
      background-color: #fcfcfc;
      padding:20px 0;
      .Disabled {
        pointer-events: none;
        opacity: 0.5;
      }
      .MethodsWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        .MethodBox {
          cursor: pointer;
          border-radius: 8px;
          height: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-basis: 26%;
          &:first-child{
            @include rtl(margin-right, margin-left, 25px);
          }
          .visaImg {
            @include rtl(margin-left , margin-right, 5px);
          }
        }
        .SelectedBox{
          border: 3px solid #8802b4;
        }
      }
      .PromoCode {
        display: flex;
        align-items: center;
        padding: 0 20px;
        margin-bottom: 15px;
        .PromoCodeIcon {
          @include rtl(margin-right,margin-left, 10px);
        }
        .PromoLabel {
          font-size: 18px;
          line-height: 30px;
          color: #000000;
          margin: 0;
        }
        .Pointer {
          cursor: pointer;
        }
        .PromoText {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex: 1;
        }
        .PromoIcon {
          cursor: pointer;
        }
      }
      .DiscountMessage {
        h3 {
          @include rtl_prop(text-align,left,right);
          padding: 0 20px;
          font-size: 20px;
          line-height: 30px;
          color: #000000;
          margin: 0;
          .Original {
            color:#E90000;
            text-decoration: line-through;
            font-weight: bold;
            margin: 0 5px;
          }
          .AfterDiscount{
            color: #248800;
            font-weight: bold;        
          }
        }
      }
    }

    .Button {
      margin-top: 26px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .BookingServiceForm {
    .FirstSection {
      padding-top: 12px;
      padding-bottom: 35px;
      padding-left: 25px;
      padding-right: 25px;
    }
    .SecondSection {
      .TimeSlotWrapper {
        padding: 0 40px;
      }
      .CalenderSection {
        .Calender {
          padding-left: 40px;
          padding-right: 40px;
        }
        .CalenderTime {
          padding-left: 19px;
          padding-right: 19px;
        }
      }
    }
  }
}
@media screen and (max-width: 577px) {
  .BookingServiceForm {
    .FirstSection {
      padding-top: 33px;
      padding-bottom: 44px;
      padding-left: 25px;
      padding-right: 25px;
      margin-bottom: 0px;
      .TextArea {
        margin-bottom: 17px;
      }
      input[type="text"] {
        margin-bottom: 20px;
      }
    }
    .SecondSection {
      @include rtl(padding-right, padding-left, 0);
      @include rtl(padding-left, padding-right, 0);
      margin-top: 22px;
      margin-bottom: 55px;
      .TimeSlotWrapper {
        padding: 0 35px;
      }
      .CalenderSection {
        padding-top: 41px;
        .Calender {
          padding-left: 35px;
          padding-right: 35px;
        }
        .CalenderTime {
          padding-left: 17px;
          padding-right: 17px;
          margin-top: 42px;
        }
      }
    }
  }
}
@media screen and (max-width: 360px) {
  .BookingServiceForm {
    .FirstSection {
      h3 {
        font-size: 16px;
        line-height: 21px;
      }
    }
    .SecondSection {
      .CalenderSection {
        img {
          width: 58%;
          height: 100%;
        }
        h3 {
          font-size: 16px;
          line-height: 21px;
        }
        .TimeSlotWrapper {
          padding: 0 25px;
        }
        .Calender {
          padding-left: 25px;
          padding-right: 25px;
        }
      }
    }
  }
}
