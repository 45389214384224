@import "../../../../../assets/styles/mixins.scss";

.ForPassSuccess {
  padding-left: 56px;
  padding-right: 56px;
  @include rtl_prop(text-align, left, right);
  h3 {
    line-height: 37px;
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 108px;
  }
  a {
    font-size: 16px;
    margin-bottom: 0;
    line-height: 21px;
    color: #68046b;
  }
  a:hover {
    color: #68046b;
    text-decoration: none;
  }
  .ServicesPath {
    width: 100%;
    height: 55px;
    border-radius: 20px;
    background-color: #eedcff;
    color: #68046b;
    margin-bottom: 58px;
  }
  img{
    margin-top: 76px;
    margin-bottom: 48px;
  }
}
@media screen and (max-width: 1024px) {
  .ForPassSuccess {
    padding-left: 0px;
  padding-right: 0px;
  }
}
@media screen and (max-width: 577px) {
  .ForPassSuccess {
    padding-left: 0px;
    padding-right: 0px;
    img{
      margin-top: 36px;
      margin-bottom: 34px;
    }
    
    h3 {
      line-height: 37px;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 14px;
    }
     .ServicesPath {
      margin-bottom: 33px;
    }
  }
}
