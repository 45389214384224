@import '../../../../../assets/styles/mixins.scss';
.HealthyHeader{
    @include rtl_prop(text-align, left, right);
    margin-top: 22px;
    margin-bottom: 16px;
    background-color: #FCFCFC;
    img{
        margin-top: 39px;
        margin-bottom: 39px;
    }
    h3{
        font-size: 28px;
        line-height: 21px;
        margin-bottom: 14px;
        margin-top: 37px;
    }
    p{
        font-size: 24px;
        line-height: 37px;
        font-weight: bold;
        color: #4B0064;
    }
}
@media (max-width: 577px) {

    .HealthyHeader{
        margin-top: 17px;
        img{
            margin-top: 37px;
            margin-bottom: 0px;
        }
        h3{
            margin-bottom: 8px;
            margin-top: 14px; 
        }
        p{
            font-size: 20px;
            line-height: 32px;
            margin-bottom: 33.5px;
        }
    }
}