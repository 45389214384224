.CallRequestSuccess {
  background-color: #fcfcfc;
  border-radius: 8px;
  margin-bottom: 35px;
  p {
    
    line-height: 21px;
    font-size: 16px;
    margin-bottom: 0;
  }
  .Main {
    width: 192px;
    text-align: center;
    margin-bottom: 81px;
  }
  h3 {
    font-size: 35px;
    line-height: 37px;
    font-weight: bold;
    margin-bottom: 0px;
  }
  .successIm{
      margin-top: 45px;
      margin-bottom: 11px;
  }

  hr {
    border: none;
    height: 4px;
    background-color: #cfc7d2;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .bellIm{
      margin-top: 32px;
  }
  .Button{
      margin-top: 54px;
      margin-bottom: 52px;
  }
}
@media screen and (max-width: 778px) {
    .CallRequestSuccess {
        margin-bottom: 31px;
        
        .successIm{
            margin-top: 33px;
            margin-bottom: 27px;
        }  
        .Main{
            margin-bottom: 29px;
        }
        .bellIm{
            margin-top: 23px;
            .ParaBell{
              padding-right: 32px;
              padding-left: 32px;
            }
        }
        .Button{
            margin-top: 22px;
            margin-bottom: 26px;
        }
      }
    
}