@import '../../../../../assets/styles/mixins.scss';
.CallRequestHeader {
@include rtl_prop(text-align, left, right);
border-radius: 8px;
  margin-top: 22px;
  margin-bottom: 23px;
  background-color: #fcfcfc;
  
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
  .phoneImg {
    margin-bottom: 20px;
    margin-top: 21px;
    width: 117px;
  }
  .customP {
    margin-top: 89px;
    line-height: 37px;
    font-size: 24px;
    font-weight: bold;
    color:#000000;
  }
  .greenBox{
    margin-top: 53px;
    @include rtl(padding-left,padding-right,40px);
    @include rtl(padding-right,padding-left,25px);
    background: #ECFFE5 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 4px #00000029;
    border-radius: 8px;
    position: relative;
    p{
        line-height: 40px;
        font-size: 30px;
        font-weight: bold;
        color: #248800;
        padding-top: 10px;
        padding-bottom: 19px;
    }
    .hand{
        width: 50px;
        height: 45px;
    }
    .faCustom{
        position: absolute;
        top: -7px;
        @include rtl(left , right ,12px);

    }
} 
}
@media screen and (max-width: 1024px) {
  .CallRequestHeader {
    .customP {
      margin-top: 50px;
      font-size: 21px;
      
    }
  }
}
@media screen and (max-width: 768px) {
  .CallRequestHeader {
    .greenBox{
      p{
        font-size: 24px;
      }
    }
  }
}
@media screen and (max-width: 557px) {
  .CallRequestHeader {
    .phone-img {
      margin-top: 24px;
      margin-bottom: 4px;
    }
    h4 {
      line-height: 21px;
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: bold;
      text-align: center;
    }
    .customP {
      line-height: 34px;
      font-size: 16px;
      margin-top: 0px;
    }
    .Info{
      padding-right: 42px;
      padding-left: 42px;
      text-align: center;

    }
    .phoneImg{
        margin-top: 24px;
        margin-bottom: 4px;
    }
    .greenBox{
        margin-top: 0px;
        margin-bottom: 20px;
    }
  }
}
@media screen and (max-width: 320px){
  .CallRequestHeader {
    .customP {
      font-size: 14px;
    }
    .greenBox{
      p{
        font-size: 21px;
      }
    }
  }
}

