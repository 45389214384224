@import "../../../assets/styles/mixins.scss";
.AuthInput {
  @include rtl_prop(text-align, left, right);
  input {
    height: 45px;
    width: 100%;
    background: #f4f4fc 0% 0% no-repeat padding-box;
    border: 1px solid #e7ddeb;
    border-radius: 4px;
    &:focus {
      box-shadow: none;
      border: 2px solid #8802b4;
      background-color: #f4f4fc;
    }
  }
  input[type="file"] {
    display: none;
  }
  .CustomFileInput {
    display: flex;
    align-items: center;
    width: 100%;
    height: 45px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e7ddeb;
    border-radius: 4px;
    
    .Placeholder{
      color: #B1B1B1 ;
      font-weight: normal;
    }
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .addonCustom {
    position: absolute;
    @include rtl(left, right, 90%);
    top: 6%;
    width: 10%;
    margin-top: 8px;
    height: 25px;
    cursor: pointer;
    color: #716274;
  }
}
