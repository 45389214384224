.AccountAddress{
    padding-top: 34px;
    p{
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 0px;
    }
    .HeadPara{
        font-weight: bold;
        color: #4B0064;
        line-height: 37px;
    }
    .Map{
        margin-top: 12px;
        margin-bottom: 60px;
        
            width: 60%;
        
    }
}
@media (max-width: 577px) {
    .AccountAddress{
        padding-top: 14px;
        .Map{
            margin-top: 13px;
            margin-bottom: 39px;
            width : 100%
        }
    }
}