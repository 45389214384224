@import './../../../../assets/styles/mixins.scss';
.Notifications {
  .NoNotification {
    font-size: 32px;
    margin-top: 75px;
    margin-bottom: 75px;
    text-align: center;
  }
  .Content {
    background-color: #fcfcfc;
    padding-bottom: 30px;
    margin-bottom: 53px;
  }
  .TogglerContainer {
    background-color: #fcfcfc;
    padding-top: 20px;
    .Toggler {
      // width: 250px;
      height: 31px;
      margin-bottom: 37px;
      background-color: transparent;
      .Active {
          background-color: #8802b4;
          color: white;
          z-index: 2;
        }
        button {
          border-radius: 20px;
          position: relative;
          float: left;
          border: none;
          outline: none;
          cursor: pointer;
          transition: 0.3s;
          color: #8802b4;
          background-color: #E7DDEB;
          z-index: 1;
          // width: 50%;
          padding: 10px 30px;
          &:first-child {
            @include rtl(margin-right,margin-left,-22px);
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
  }

@media (max-width: 577px) {
  .NoNotification {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .Toggler {
    margin-bottom: 24px;
  }
  .Content {
    margin-bottom: 46px;
  }
  .OrderNotificationContainer {
    padding-right: 15px;
    padding-left: 15px;
  }
}
