.HealthyAttachment {
  padding-top: 45px;
  padding-bottom: 45px;
  background-color: #fcfcfc;
  p {
    font-size: 16px;
    line-height: 21px;
  }
  .plusImg {
    cursor: pointer;
    transition-property: all;
    transition-duration: 0.35s;
    transition-timing-function: ease-out;
    transition-delay: 0s;

    &:hover {
      transform: scale(1.2);
    }
  }
}
