@import '../../../../../assets/styles/mixins.scss';
.CallRequestSubmit{
    @include rtl_prop(text-align, left, right);
    
    img{
        margin-top: 74px;
        margin-bottom: 31px;
    }
    p{
        margin-bottom: 75px;
        line-height: 21px;
        font-size: 16px;
        font-weight: bold;
        color: #4B0064;
    }
    .Button{
        padding-left: 42px;
        padding-right: 42px;
    
    .submitButton{
        margin-bottom: 42px;
    }
}
}
@media screen and (max-width: 577px) {
    .CallRequestSubmit{
        padding-left: 27px;
        padding-right: 27px;
        img{
            margin-top: 25px;
            margin-bottom: 20px;
        }
        p{
            margin-bottom: 25px;
        }
        .Button{
            padding-left: 46px;
        padding-right: 46px;
        .submitButton{
            margin-bottom: 38px;
        }
    }
        img{
            width: 174px;
            height: 161px;
        }
    }
}
@media screen and (max-width: 375px) {
    .CallRequestSubmit{
    .Button{
        padding-left: 10px;
    padding-right: 10px;
    font-size: 20px;
    }}
}