.ProfileInput{
    input{
        width: 60%;
        height: 45px;
        background-color: #fff;
        border: 1px solid #E7DDEB;
        border-radius: 8px;
        &:focus{
            box-shadow: none;
            border: 2px solid #8802B4;
            
          }
    }
}
@media (max-width: 577px) { 
    .ProfileInput{
        input{
            width: 100%;
        }
    }
 }