@import "../../../../../assets/styles/mixins.scss";
.ContactUsHeader {
  @include rtl_prop(text-align, left, right);
  background: #fcfcfc 0% 0% no-repeat padding-box;
  border-radius: 8px;
  position: relative;
  @include rtl(padding-left,padding-right,63px);
  margin-top: 22px;
  margin-bottom: 23px;
  
  
  h3 {
    margin-top: 37px;
    font-size: 28px;
    line-height: 21px;
    margin-bottom: 15px;
    color: #000000;
    font-weight: bold;
  }
  p{
      font-size: 24px;
      line-height: 37px;
      color: #000000;
      font-weight: bold;
      margin-bottom: 45px;

  }
  img{
      position: absolute;
      @include rtl(right,left,0px);
      @include rtl(padding-right,padding-left,65px);
  }
}
@media screen and (max-width: 778px) {
    .ContactUsHeader {
        p{
            font-size: 18px;
        }
    }
}
@media screen and (max-width: 577px) {
    .ContactUsHeader {
        margin-top: 17px;
        margin-bottom: 30px;
        @include rtl(padding-left,padding-right,15px);
        @include rtl(padding-right,padding-left,15px);
        h3{
            margin-top: 20px;
            margin-bottom: 6px;
        }
        p{
            margin-bottom: 11px;
            line-height: 32px;
            font-size: 24px;
        }
        img{
            position: relative;
            @include rtl(padding-right,padding-left,0);
        }
    }
}