@import "../../../../assets/styles/mixins.scss";
.ForgetPassword {
  @include rtl_prop(text-align, left, right);
  .content {
    margin-top: 39px;
  }
  .Back {
    width: 92px;
    height: 30px;
    background-color: #eedcff;
    color: #68046b;
    border-radius: 20px;
    margin-top: 60px;
    
  }
  p {
    margin-bottom: 0;
  }
  img {
    margin-bottom: 0;
    margin-top: 44px;
  }
  .Back a {
    color: #68046b;
  }
  .Back a:hover {
    color: #68046b;
    text-decoration: none;
  }
}
@media screen and (max-width: 768px) {
  .ForgetPassword {
    .content {
      margin-top: 19px;
    }
    .Back {
      margin-top: 19px;
    }
    img {
      margin-top: 8px;
    }
  }
}
