@import './../../../assets/styles/mixins.scss';
.LandingPageCard {
  width: 216px;
  height: 286px;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  border-radius: 16px;
  background-color: #fff;
  transition-property: all;
  transition-duration: 0.35s;
  transition-timing-function: ease-out;
  transition-delay: 0s;
  font-family: din;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 5px 17.5px #001900;
  }
  .GrayCircle {
    width: 127px;
    height: 127px;
    margin-top: 30px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  p {
    font-size: 16px;
    line-height: 21px;
    color: #8802b4;
    font-weight: bold;
    margin-bottom: 0px;
  }
  .CardButton {
    @include rtl_prop(padding,7px 32px,7px 40px);
    background-color: #ffffff;
    border: 2px solid #8802b4;
    border-radius: 8px;
    color: #8802b4;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    font-family: din;
    &:focus {
      box-shadow: none;
    }
  }
  &:hover {
    background-color: #eedcff;
    .CardButton {
      background-color: #8802b4;
      span {
        color: #fff;
      }
    }
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
}
