.NotificationsHeader {
  background-color: #fcfcfc;
  margin-top: 22px;
  border-radius: 8px;
      margin-bottom: 23px;
  h3{
      font-size: 28px;
      line-height: 21px;
      margin-top: 37px;
      margin-bottom: 9px;
  }
  p{
      margin-bottom: 0px;
      color: #4B0064;
      font-size: 20px;
      line-height: 32px;
      font-weight: bold;
  }
  figure {
    position: relative;
    margin-bottom: 25px;
    margin-top: 24px;
    .NotifNum {
      position: absolute;
      width: 32px;
      height: 32px;
      background-color: #730198;
      color: #fff;
      top: 0;
      border-radius: 50%;
      border: 3px solid #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 16px;
        line-height: 21px;
        font-weight: bold;
        
      }
    }
  }
}
@media (max-width: 577px) {
  .NotificationsHeader {
      margin-top: 17px;
      margin-bottom: 30px;
      padding-right: 42px;
      padding-left: 42px;
    figure {
      margin-bottom: 20px;
      margin-top: 22px;
    }
    h3{
        margin-top: 0px;
    }
    p{
        margin-bottom: 29px;
    }
  }
}
@media (max-width: 375px){
    .NotificationsHeader {
        padding-right: 15px;
        padding-left: 15px;
    }
}
