@import "../../../../assets/styles/mixins.scss";

.CallRequest {
  .first {
    @include rtl(padding-left, padding-right, 0px);
    .Gray {
      border-radius: 8px;
      background-color: #fcfcfc;
      @include rtl(padding-left, padding-right, 62px);
      @include rtl(padding-right, padding-left, 82px);
    }
  }
  .second {
    @include rtl(padding-right, padding-left, 0px);
    
    .Gray {
      .Verify{
        padding-top: 1px;
        padding-left: 40px;
        padding-right: 40px;
      
      }
      padding-left: 0px;
      padding-right: 0px;
      background: #FCFCFC 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 4px #00000029;
    border-radius: 8px;
      
      padding-bottom: 14px;
    }
  }
  .SecondSection{
    margin-bottom: 67px;
  }
}
@media screen and (max-width: 1024px) {
  .CallRequest {
    .first {
      .Gray{
      @include rtl(padding-left, padding-right, 30px);
        @include rtl(padding-right, padding-left, 30px);
      }
    }
    .second{
      .Gray{
        .Verify{
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 577px) {
  .CallRequest {
    .first {
      @include rtl(padding-right,padding-left, 0px);
      
      .Gray {
        @include rtl(padding-left, padding-right, 25px);
        @include rtl(padding-right, padding-left, 35px);
      }
    }
    .second {
      @include rtl(padding-left, padding-right, 0px);
      .Gray {
        .Verify{
          padding-top: 1px;
          padding-left: 15px;
          padding-right: 15px;
        
        }
        padding-left: 0px;
        padding-right: 0px;
      }
    }
    .SecondSection{
      margin-bottom: 28px;
    }
  }
}
