.BookingServicesSuccess{
    .BreadCrumbCustom {
        background-color: transparent;
        padding: 0px;
        li {
          font-size: 14px;
          line-height: 34px;
          font-weight: bold;
          a {
            color: #4b0064;
          }
          span {
            color: #A57FB1
          }
        }
      }
}