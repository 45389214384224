@import "../../../../assets/styles/mixins.scss";

.BookingServiceHeader {
  background-color: #fcfcfc;
  padding-right: 42px;
  padding-left: 42px;
  border-radius: 8px;
  p {
    font-size: 24px;
    line-height: 37px;
    margin-bottom: 0px;
  }
  .FeeHeader {
    font-family: Euclid;
  }
  .HeaderHead {
    font-size: 28px;
    line-height: 33px;
    margin-top: 37px;
    margin-bottom: 15px;
  }
  @include rtl_prop(text-align, left, right);
  .GrayCircle {
    height: 114px;
    width: 114px;
    background-color: #eeeeee;
    border-radius: 50%;
    margin-top: 20px;
    margin-bottom: 32px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .FeePara {
    color: #248800;
    margin-top: 8px;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 21px;
  }
  .GreenBox {
    @include rtl(padding-left, padding-right, 57px);
    @include rtl(padding-right, padding-left, 16px);
    box-shadow: 0px 2px 4px #00000029;
    border-radius: 8px;
    background-color: #ecffe5;
    position: relative;
    margin-top: 38px;

    p {
      line-height: 21px;
      font-size: 16px;
      color: #248800;
      width: 100px;
      margin-top: 14px;
      margin-bottom: 12px;
    }
    h3 {
      line-height: 33px;
      font-size: 25px;
      color: #248800;
      margin-top: 12px;
    }

    .hand {
      width: 43px;
      height: 38px;
      margin-top: 12px;
    }
    .faCustom {
      position: absolute;
      top: -7px;
      @include rtl(left, right, 12px);
      color: #9ccf8a;
    }
  }
}
@media screen and (max-width: 991px) {
  .BookingServiceHeader {
    .GreenBox {
      @include rtl(padding-left, padding-right, 35px);
      @include rtl(padding-right, padding-left, 16px);
    }
  }
}
@media screen and (max-width: 767px) {
  .BookingServiceHeader {
    padding-right: 25px;
    padding-left: 25px;
    .HeaderHead {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    .GrayCircle {
      margin-top: 23px;
      margin-bottom: 13px;
    }

    .GreenBox {
      @include rtl(padding-left, padding-right, 48px);
      @include rtl(padding-right, padding-left, 16px);
      margin-top: 18px;
    }
    .FeePara {
      margin-bottom: 26px;
    }
  }
}
@media screen and (max-width: 320px) {
  .BookingServiceHeader {
    .GreenBox {
      p {
        font-size: 12px;
      }
      h3 {
        font-size: 20px;
      }
    }
  }
}
