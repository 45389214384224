@import "../../assets/styles/mixins.scss";
.LandingPage {
  @include rtl_prop(text-align, left, right);
  background-image: url("../../assets/img/landingPage/Group\ 62537.png");
  background-repeat: no-repeat;
  background-position: center top;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  .FogBackgroundContainer {
    padding-left: 105px;
    padding-right: 105px;
    padding-top: 81px;
    padding-bottom: 96px;
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      #ffffff 26.56%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  .CardMargin {
    div {
      &:not(:last-child) {
        @include rtl(margin-right, margin-left, 20px);
      }
    }
  }
  p {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 0px;
  }
  .pageHeaders {
    z-index: 2 !important;
    margin-bottom: 33px;
    padding-bottom: 0;
  }
  .header {
    font-weight: bold;
    font-size: 68px;
    line-height: 116px;
    margin-bottom: 0px;
    color: #320000;
    text-align: center;
    @media (max-width:991.99px) and (min-width:768px){
      font-size: 50px;
      line-height: 80px;
    }
  }
  .header2 {
    font-weight: bold;
    font-size: 36px;
    line-height: 61px;
    color: rgba(50, 0, 0, 0.5);
    margin-bottom: 0px;
    text-align: center;
    @media (max-width:991.99px) and (min-width:768px){
      font-size: 32px;
      line-height: 56px;
    }
  }
  .firstSection {
    margin-bottom: 100px;
    z-index: 2 !important;
    .SmallCard {
      a {
        color: #4b0064;
        &:hover {
          text-decoration: none;
          color: #4b0064;
        }
      }

      background-color: #fff;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 8px;
      margin-bottom: 8px;
      padding-top: 18px;
      padding-bottom: 18px;
      &:hover {
        background-color: #eedcff;
      }

      img {
        width: 50px;
        height: 50px;
      }
      p {
        margin-top: 10px;
        font-weight: bold;
      }
    }
  }
  .secondSection {
    background-color: #fcfcfc;
    box-shadow: 0px 2px 4px #00000029;
    border-radius: 8px;
    margin-bottom: 76px;
    margin-right: 102px;
    margin-left: 102px;
    @media (max-width:991.99px) and (min-width:768px){
      margin-right: 40px;
      margin-left: 40px;
    }
    .phoneImg {
      margin-top: 94px;
      width: 81px;
      height: 102px;
    }
    .order {
      line-height: 37px;
      font-size: 25px;
      font-weight: bold;
      margin-bottom: 0px;
      color: #4b0064;
    }
    .callRequestButton {
      line-height: 27px;
      font-family: din;
      background-color: #fcfcfc;
      color: #68046b;
      padding: 7px 40px;
      border: 2px solid #68046b;
      margin-bottom: 42px;
      span {
        font-size: 20px;
        font-weight: bold;
        line-height: 27px;
      }
      &:hover {
        background-color: #68046b;
        color: #fff;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .LandingPage {
    .FogBackgroundContainer {
      padding-left: 105px;
      padding-right: 105px;
      padding-top: 81px;
      padding-bottom: 96px;
    }
  }
}
@media screen and (max-width: 778px) {
  .LandingPage {
    background-size: 657px 442px;
    margin-bottom: 15px;
    .FogBackgroundContainer {
      padding-left: 41px;
      padding-right: 41px;
      padding-top: 52px;
      padding-bottom: 38px;
    }
    .firstSection {
      margin-bottom: 38px;
      padding-right: 15px;
      padding-left: 15px;
    }
    .secondSection {
      margin-bottom: 40px;
      margin-right: 40px;
      margin-left: 40px;
      .phoneImg {
        margin-top: 108px;
      }
      .callRequestButton {
        p {
          font-size: 14px;
          line-height: 20px;
          font-weight: bold;
          margin-top: 5px;
        }
      }
    }
  }
}
@media screen and (max-width: 577px) {
  .LandingPage {
    .header {
      font-size: 24px;
      line-height: 32px;
    }
    .header2 {
      font-size: 24px;
      line-height: 32px;
    }
    .firstSection {
      padding-left: 0;
      padding-right: 0;
    }
    background-size: 300px 220px;
    .secondSection {
      margin-bottom: 40px;
      margin-left: 25px;
      margin-right: 25px;
      .phoneImg {
        margin-top: 30px;
      }
      .callRequestButton {
        p {
          font-size: 14px;
          line-height: 20px;
          font-weight: bold;
          margin-top: 5px;
        }
      }
    }
  }
}
@media screen and (max-width: 320px) {
  .LandingPage {
    background-size: 250px 220px;

    .firstSection {
      .SmallCard {
        padding-right: 8px;
        padding-left: 8px;
        p {
          font-size: 13px;
        }
        img {
          margin-top: 5px;
          width: 35px;
          height: 35px;
        }
      }
    }
  }
}
.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
.whiteWaves {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: -1%;
}
.whiteOverlay {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  background-image: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 26.56%, rgba(255, 255, 255, 0) 100%);
}
