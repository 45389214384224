@import "../../../../assets/styles/mixins.scss";
.BookingServiceCard {
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding-bottom: 15px;
    transition: box-shadow 0.2s ease-in-out, transform 0.35s ease-in-out;
    img {
      @include rtl_prop(margin-left,margin-right,13px);
      max-width: 77px;
      max-height: 77px;
    }
    .DescriptionWrapper {
        position: relative;
        border-radius: 8px;
        padding: 7px 11px;
        background-color: #F4F4FB;
        height: 54px;
        .InfoIcon {
            position: absolute;
            @include rtl(right,left,10px);
            top: -15px;
            margin: 0;
        }
        .DescriptionText {
            margin: 0;
            font-size: 14px;
            line-height: 20px;
            color: #000000;
            font-weight: 500;
            display: block!important;
            display: -webkit-box!important;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
        }
    }
    a {
      .CardText {
        font-size: 16px;
        line-height: 21px;
        font-weight: bold;
        margin-bottom: 0px;
        color: #4b0064;
      }
      &:hover {
        text-decoration: none;
      }
    }
    &:hover {
        box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);
    }
  }
  @media screen and (max-width: 320px) {
    .BookingServiceCard {
      a {
        .CardText {
          font-size: 12px;
        }
      }
    }
  }
  