@import '../../../../../assets/styles/mixins.scss';
.NotificationCard{
    @include rtl_prop(text-align, left, right);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E7DDEB;
    border-radius: 8px;
    margin-bottom: 16px;
    padding-top :28px;
    padding-bottom: 28px ;
    margin-left: 0px !important;
    margin-right: 0px !important;
    cursor: pointer;
    color : #1659A1;
    @include rtl(padding-left,padding-right,60px);
    @include rtl(padding-right,padding-left,20px);
    &:hover {
        background: #F4F4FC 0% 0% no-repeat padding-box;
    }
    h3{
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 5px;
    }
    p{
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 0;
        
    }
    span{
        font-size: 16px;
        line-height: 21px;
    }
}
@media (max-width: 577px) {
    .NotificationCard{
        @include rtl(padding-left,padding-right,25px);
        @include rtl(padding-right,padding-left,20px);
    }
}