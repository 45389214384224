@import '../../../../assets/styles/mixins.scss';
.Register {
  @include rtl_prop(text-align , left,right);
  .VerifyHeader{
    margin-top: 60px;
    margin-bottom: 39px;
  }
  .VerifyButton{
    margin-top: 26px;
    margin-bottom: 34px;
  }
  
  p {
    font-size: 16px;
    line-height: 21px;
    color: #000000;
  }
  .logo {
    margin-top: 24px;
    margin-bottom: 14px;
  }
  .errorMessage { 
    height: 21px;
    color: red;
  }
  h3 {
    line-height: 37px;
    font-size: 35px;
    font-weight: bold;
    color: #000000;
  }
  .HeaderRegister{
    margin-bottom: 19px;
  }
  a {
    color: #730198;
    font-weight: bold;
    line-height: 21px;
    font-size: 16px;
    text-decoration: underline;
  }
  a:hover {
    color: #8802b4;
    text-decoration: underline;
  }
  .RegisterButton {
    margin-top: 61px;
    margin-bottom: 35px;
  }

  .registerLink {
    a{
      font-weight: bold;
    }
    p{
      font-weight: normal;
    }
  }
  .registerLinkDiv {
    margin-bottom: 26px;
    
  }
  .Back {
    width: 92px;
    height: 30px;
    background-color: #eedcff;
    
    background: #EEDCFF 0% 0% no-repeat padding-box;
    border-radius: 20px;
    p{
      color: #68046b;
      font-weight: normal;
    }
  }
  .font-awesome {
    font-weight: bold;
    font-size: 16px;
  }
  .serviceSearch {
    padding-top: 15px;
    margin-top: 108px;
    background-color: #eedcff;
    height: 55px;
    width: 300px;
    border-radius: 30px;
  }
  .LinkSpace {
    margin-bottom: 73px;
  }
  .MainContent {
    margin-top: 48px;
    padding-left: 56px;
    padding-right: 56px;
  }
  .ServicesPath {
    width: 100%;
    height: 55px;
    color: #68046B;
    border-radius: 20px;
    background: #EEDCFF 0% 0% no-repeat padding-box;
    
    margin-top: 108px;
    margin-bottom: 58px;
    p{
      color: #68046B;

    }
  }
}
@media screen and (max-width: 1024px) {
  .Register {
    .MainContent {
      padding-left: 20px;
      padding-right: 0px;
    }
  }
}
@media screen and (max-width: 778px) {
  .Register {
    .MainContent {
      padding-left: 20px;
      padding-right: 0px;
    }
  }
}
@media screen and (max-width: 577px) {
  .Register {
    .VerifyHeader{
      margin-top: 19px;
      margin-bottom: 0px;
    }
    .VerifyButton{
      margin-top: 26px;
      margin-bottom: 23px;
    }
    img{margin-bottom: 0;}
    .logo{
      margin-bottom: 13px;
    }
    .HeaderRegister{
      margin-bottom: 26px;
    }
    .LinkSpace {
      margin-bottom: 29px;
    }
    .MainContent {
      margin-top: 28px;
      padding-left: 0px;
    padding-right: 0px;
    }
    .ServicesPath {
      margin-top: 27px;
      margin-bottom: 43px;
    }
    .RegisterButton {
      margin-top: 40px;
      margin-bottom: 24px;
    }
    .registerLinkDiv {
      margin-bottom: 34px;
    }
  }
  @media screen and (min-width: 320px) {
    .Register {
      .ServicesPath {
        width: 250px;
      }
    }
  }
}
