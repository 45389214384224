@import "../../../../../assets/styles/mixins.scss";
.EditAccount {
  @include rtl_prop(text-align, left, right);
  @include rtl(padding-left, padding-right, 66px);
  @include rtl(padding-right, padding-left, 77px);
  background-color: #fcfcfc;
  h3 {
    font-size: 24px;
    line-height: 37px;
    color: #4b0064;
    margin-top: 35px;
    margin-bottom: 40px;
  }
  p {
    font-size: 16px;
    font-weight: bold;
    line-height: 37px;
    color: #4b0064;
    margin-bottom: 14px;
  }
}
@media (max-width: 577px) {
  .EditAccount {
    @include rtl(padding-left, padding-right, 15px);
    @include rtl(padding-right, padding-left, 15px);
    h3 {
      margin-top: 17px;
      margin-bottom: 20px;
    }
  }
}
