@import "../../../../../assets/styles/mixins.scss";
.PasswordForm {
  @include rtl_prop(text-align, left, right);
  padding-right: 34px;
  padding-left: 34px;
  h3 {
    width: 342px;
    line-height: 37px;
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 0px;
  }
  p {
    font-size: 16px;
    margin-bottom: 0;
    line-height: 21px;
  }
  .AuthButton {
    padding-top: 40px;
    margin-bottom: 71px;
  }
  .errorMessage {
    color: red;
  }
}
@media screen and (max-width: 1024px) {
  .PasswordForm {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media screen and (max-width: 768px) {
  .PasswordForm {
    padding-right: 0px;
    padding-left: 0px;
    h3 {
      font-size: 24px;
    }
    .AuthButton {
      margin-bottom: 41px;
    }
  }
}
