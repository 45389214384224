@import "../../../../../assets/styles/mixins.scss";
.JoinUsForm {
  @include rtl_prop(text-align, left, right);
  
  .FormContainer {
    
    @include rtl(padding-left, padding-right, 66px);
    padding-top: 36px;
    padding-bottom: 39px;
    margin-bottom: 32px;
    background: #fcfcfc 0% 0% no-repeat padding-box;
    border-radius: 8px;
    p {
      font-size: 16px;
      line-height: 37px;
      font-weight: bold;
      color: #4b0064;
      margin-bottom: 0;
    }
    .errorMessage{
      color: red;
      font-weight: normal;
    }
    .JoinUsServicesPara {
      margin-bottom: 14px;
    }
    .JoinUsInfoPara {
      margin-bottom: 14px;
    }
    .FilePara{
        margin-bottom: 10px;
    }
    .FileInput{
       margin-bottom: 20px; 
    }
    
    .ContactUsInput {
      width: 566px;
      .TextArea {
        margin-top: 15px;
        height: 115px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #e7ddeb;
        border-radius: 8px;
        &:focus {
          box-shadow: none;
          border: 2px solid #8802b4;
        }
      }
    }
  }
  .SubmitButton{
      width: 334px;
      margin-bottom: 61px;
  }
}
@media screen and (max-width: 577px) {
  .JoinUsForm {
    .FormContainer {
      @include rtl(padding-left, padding-right, 15px);
      padding-top: 29px;
      padding-bottom: 8px;
      margin-bottom: 20px;
      p {
        font-size: 14px;
        line-height: 22px;
      }
      .JoinUsServicesPara {
        margin-bottom: 27px;
      }
      .ContactUsInput {
        width: 100%;
      }
      
    }
    .SubmitButton{
        width: 100%;
        margin-bottom: 35px;
    }
  }
}
