@import "../../../../../assets/styles/mixins.scss";

.LoginForm {
  @include rtl_prop(text-align, left, right);
  padding-top: 24px;
  padding-bottom: 87px;
  .LogoImg {
    margin-bottom: 14px;
  }
  p {
    font-size: 16px;
    line-height: 21px;
  }
  h3 {
    line-height: 37px;
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  a {
    color: #8802b4;
    font-weight: bold;
    line-height: 21px;
    font-size: 16px;
  }
  a:hover {
    color: #68046b;
  }
  .register {
    width: 100%;
    background-color: #e7ddeb;
    color: #68046b;
    height: 58px;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    display: flex;
    justify-content: center;

    &:hover {
      background-color: #d6cdda;
    }
    p {
      font-size: 20px;
      font-weight: normal;
      line-height: 27px;
      margin-top: 8px;
    }
  }
  .errorMessage {
    color: red;
    line-height: 21px;
  }
  .login {
    margin-top: 40px;
    margin-bottom: 22px;
  }
}
@media screen and (max-width: 768px) {
  .LoginForm {
    padding-top: 24px;
    padding-bottom: 29.5px;
    h3 {
      margin-bottom: 26px;
    }
    .login {
      margin-top: 35px;
    }
  }
}
