@import "../../../../assets/styles/mixins.scss";

.Modal {
  @include rtl_prop(font-family,Euclid,Questv1);
  @include rtl_prop(text-align, left, right);
  padding: 42px 50px 0;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  background-color: white;
  width: 400px;
  height: 300px;
  border-radius: 3%;
  .FaCustom{
    position: absolute;
    top: 16px;
    @include rtl( right,left ,18px);
    font-size: 20px;
    height: 32px;
    width: 32px;
    color : black;
    background-color: #E7DDEB;
    border-radius: 50%;
    cursor: pointer;
    &:hover{
      background-color: #dab6e9;
    }
    
  }
  h3{
    font-size: 24px;
    line-height: 37px;
    margin-top: 21px;
    margin-bottom: 11px;
    font-weight: bold;
  }
  p{
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 44px;
  }
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
}
@media screen and (max-width: 778px) {
  .Modal {
    width: 60%;
    padding: 42px 50px 0;
  }
}
@media screen and (max-width: 577px) {
  .Modal {
    width: 80%;
    padding: 42px 50px 0;
  }
}
@media screen and (max-width: 320px) {
  .Modal {
    padding: 30px 30px 0;
  }
}