.FooterInput {
  input {
    height: 45px;
    background: #fefefe 0% 0% no-repeat padding-box;
    border: 1px solid #e7ddeb;
    border-radius: 8px;
  }
  .btnCustom,
  .btnCustom:hover,
  .btnCustom:active,
  .btnCustom:visited {
    background: #8802b4 0% 0% no-repeat padding-box;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    border-color: #8802b4;
  }
}
