@import "../../../../../assets/styles/mixins.scss";
.RateModal {
  @include rtl_prop(text-align, left, right);
  @include rtl_prop(font-family,Euclid,Questv1);
  padding-top: 42px;
  padding-left: 75px;
  padding-right: 75px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 5%;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  background-color: white;
  width: 512px;
  height: 480px;
  opacity: 100%;
  border-radius: 3%;
  .FaCustom{
    position: absolute;
    top: 16px;
    @include rtl( right,left ,18px);
    font-size: 20px;
    height: 32px;
    width: 32px;
    color : black;
    background-color: #E7DDEB;
    border-radius: 50%;
    cursor: pointer;
    
  }
  h3{
      font-size: 24px;
      color: #4B0064;
      line-height: 37px;
      margin-top: 20px;
      margin-bottom: 30px;
  }
  p{
      margin-bottom: 12px;
      font-size: 16px;
      line-height: 21px;
  }
  textarea{
      margin-bottom: 12px;
  }
  .Rating{
      margin-bottom:15px;
  }
  
}
.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
  }
@media (max-width: 577px) {
    .RateModal {
      width: 90%;
      padding-top: 42px;
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  @media (max-width: 360px) {
    .RateModal {
        width: 90%;
        padding-top: 42px;
        padding-left: 20px;
        padding-right: 20px;
      }
  }