@import "../../../../assets/styles/mixins.scss";
.MajorCard {
  cursor: pointer;
text-align: center;
  width: 148px;
  height: 148px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #e7ddeb;
  border-radius: 8px;
  @include rtl(margin-right, margin-left, 16px);
  .ImgContainer {
    
    display: inline-block;
    width: 60px;
    height: 60px;
    margin-top: 20px;
    margin-bottom: 13px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  p{
      font-size: 16px;
      line-height: 21px;
      color: #4B0064;
      font-weight: bold;
      margin-bottom: 0;
  }
}
@media screen and (max-width: 577px) {
  .MajorCard {
    @include rtl(margin-right, margin-left, 13px);
  }
}
@media screen and (max-width: 375px) {
  .MajorCard {
    width: 130px;
  height: 130px;
    @include rtl(margin-right, margin-left, 11px);
  }
}
@media screen and (max-width: 360px) {
  .MajorCard {
    width: 130px;
  height: 130px;
    @include rtl(margin-right, margin-left, 4px);
  }
}
@media screen and (max-width: 320px) {
  .MajorCard {
    width: 148px;
    height: 148px;
    
    margin-left: auto;
    
  }
}
