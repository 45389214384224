@import "../../../../../assets/styles/mixins.scss";
.AccountInfo {
  @include rtl_prop(text-align, left, right);
  @include rtl(padding-left, padding-right, 66px);
  @include rtl(padding-right, padding-left, 59px);
  padding-top: 11px;
  background-color: #fcfcfc;
  h5{
      font-size: 16px;
      line-height: 37px;
      color: #4B0064;
      margin-bottom: 0;
  }
  p{
      font-size: 16px;
      line-height: 37px;
      margin-bottom: 0;
  }
  hr{
    border: none;
    height: 4px;
    background-color: #cfc7d2;
    margin-top: 45.5px;
    margin-bottom: 0px;

  }
  .AccountAddressForm{
    padding-top: 34px;
  }
  .EditImg{
    cursor: pointer;
    transition-property: all;
    transition-duration: 0.35s;
    transition-timing-function: ease-out;
    transition-delay: 0s;

  &:hover {
    transform: scale(1.2);
  }
  }
}
@media screen and (max-width: 778px) {
  .AccountInfo {
    @include rtl(padding-left, padding-right, 23px);
    @include rtl(padding-right, padding-left, 10px);
    hr{
        margin-top: 19.5px 
    }
    .AccountAddressForm{
      padding-top: 23px;
    }
  }
}
