@import '../../../../../assets/styles/mixins.scss';
.OrderStatusCard{
    @include rtl_prop(text-align, left, right);
    cursor: pointer;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E7DDEB;
    border-radius: 8px;
    margin-bottom: 16px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    &:hover {
        background: #F4F4FC 0% 0% no-repeat padding-box;
    }
    img{
        max-width: 100%;
        max-height: 100%;
    }
    h3{
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 5px;
    }
    p{
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 0;
        
    }
    .SecondSection{
        @include rtl(border-left, border-right, 1px solid #E7DDEB);
        p{
            font-weight: bold;
        }
        span{
            font-size: 16px;
            line-height: 21px;
        }
        .Number{
            font-weight: normal;
            color: #888888;
        }
        .Date{
            margin-top: 27.5px;
        }
        .Badge{
            margin-top: 19px;
            margin-bottom: 0;
            span{
                color: #1C1C1C;
            }
        }
    }
    
}
@media (max-width: 577px) {
    .OrderStatusCard{
        

        .SecondSection{
        border-top: 1px solid #E7DDEB;
        .Date{
            margin-top: 13px;
        }
        .Badge{
            margin-top: 0px;
            margin-bottom: 13px;
        }
        }
    }}