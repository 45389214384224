.AuthButton {
  button {
    width: 100%;
    height: 58px;
    background-color: #8802B4;
    font-weight: bold;
    font-size: 20px;
    border: none;
    color: white;
    padding: auto;
    line-height: 27px;
    border-radius: 8px;
    &:hover,
    &:active,
    &:visited {
      background-color: #76049c;
      color: white;
    }
  }
}
